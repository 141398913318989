<template>
  <div class="bar-chart-main">
    <div class="chart-title">{{ props.title }}</div>
    <div class="chart-container">
      <div class="bar-chart-container" ref="barChartEl">
        <!-- The y axis -->
        <div class="y-axis-line" :style="{ top: `${yAxisTop}px`, height: `calc(100% - ${yAxisBottom - yAxisTop}px)`, left: `${yAxisLeft}px` }">
          <div v-for="(y,yInd) in yAxisValues" :key="yInd" class="y-axis-value" :style="{ top: `${y.top}px` }">
            <div :style="{ transform: y.total == 0 ? `translate(calc(-100% - 3px), 100%)` : '' }">{{ y.value }}</div>
          </div>
        </div>

        <!-- The x axis -->
        <div class="x-axis-line" :style="{ top: `${xAxisLinePosition}px` }"></div>

        <!-- The container holder all the bars -->
        <div class="bar-container" :style="getBarContainerStyle()">
          <div v-for="(b,bInd) in barData" :key="bInd" class="bar-item" :style="{
            height: `${b.height}px`,
            transform: b.totalValue < 0 ? `translateY(calc(100% + 3px))` : '',
            opacity: isBarHover(bInd) ? '1' : '0.4' }"
            @mouseover="barHoverIndex = bInd" @mouseleave="barHoverIndex = -1">
            <div class="bar-value-text" :style="{
              top: b.totalValue < 0 ? 'calc(100% + 3px)' : '',
              transform: b.totalValue >= 0 ? `translateY(calc(-100% - 3px))` : '',
              opacity: isBarHover(bInd) ? '1' : '0.4',
              fontWeight: isBarHover(bInd) ? 'bold' : 'normal' }">{{ legendHoverIndex >= 0 ? b.value[legendHoverIndex] : b.totalValue }}</div>
            <div class="bar-value-text bar-name small-bar-name" :style="{
              top: b.totalValue < 0 ? '0' : 'calc(100% + 5px)',
              transform: b.totalValue < 0 ? 'translateY(calc(-100% - 5px))' : '',
              whiteSpace: barHoverIndex == bInd ? (isBarHover(bInd) ? '' : 'nowrap') : 'nowrap' }">{{ b.name }}</div>
            <div v-for="(bVal, bValInd) in b.value" :key="bValInd"
              :style="{
                height: `${bVal / b.totalValue * 100}%`,
                backgroundColor: barColors[bValInd].color,
                opacity: legendHoverIndex == bValInd || legendHoverIndex == -1 ? 1 : 0.4
              }"></div>
          </div>
        </div>
      </div>
      <div class="legend-container">
        <div class="legend-title">Legends</div>
        <div v-for="(l, lInd) in legendToShow" :key="lInd" class="legend-item"
          @mouseover="legendHoverIndex = lInd" @mouseleave="legendHoverIndex = -1"
          :style="{ opacity: legendHoverIndex == lInd || legendHoverIndex == -1 ? '1' : '0.4' }">
          <div class="legend-color" :style="{ backgroundColor: store.state.colorListing[lInd].color }"></div>
          <div class="legend-text">{{ l }}</div>
        </div>

        <button v-if="emptyLegends.length > 0" @click="showEmptyLegends = !showEmptyLegends" style="margin-top: 5px;">{{ showEmptyLegends ? 'Hide' : 'Show more' }}</button>
        <template v-if="showEmptyLegends">
          <div v-for="(l, lInd) in emptyLegends" :key="lInd" class="legend-item"
            @mouseover="legendHoverIndex = lInd + legendToShow.length" @mouseleave="legendHoverIndex = -1"
            :style="{ opacity: legendHoverIndex == lInd + legendToShow.length || legendHoverIndex == -1 ? '0.6' : '0.4' }">
            <div class="legend-color" :style="{ backgroundColor: store.state.colorListing[lInd + legendToShow.length].color }"></div>
            <div class="legend-text">{{ l }}</div>
          </div>
        </template>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted, defineProps, watch } from 'vue';
import { useStore } from 'vuex';

const props = defineProps({
  title: { type: String, default: 'Bar Chart' }, // To set the title of the bar chart
  /*
  Sample item: [ { "name": "Item", ]
  // Sample item: [ { "name": "Item", "value": [12, 85], "valueName": ["SubItem1", "SubItem2"] } ]
  Where:
  name is the name of each bar
  value can be array where it will stack. If length of value is 1, then a solid bar will be shown
  valueName is the name for each of the item that is stack and will be shown in the legends
  */
  item: { type: Array, default: () => { return [] } } // The item to show in the bar chart
});

const store = useStore();

const barColors = store.state.colorListing;

//#region Data
const barData = ref([ // Default bar chart item
  {
    "name": "Liam Smith",
    "value": [12],
    // "value": [12, 85, 43, 56, 78, 92, 31, 64, 59, 24],
    "valueName": ["Zara"]
    // "valueName": ["Zara","Liam","Eva","Max","Leo","Mia","Jade","Finn","Nia","Ace"]
  },
  {
    "name": "Jane",
    "value": [54],
    // "value": [54, 83, 29, 68, 74, 23, 11, 62, 94, 57],
    "valueName": ["Zara"]
    // "valueName": ["Zara","Liam","Eva","Max","Leo","Mia","Jade","Finn","Nia","Ace"]
  },
  {
    "name": "Olivia Brown",
    "value": [33],
    // "value": [33, 77, 99, 84, 66, 22, 11, 59, 88, 45],
    "valueName": ["Zara"]
    // "valueName": ["Zara","Liam","Eva","Max","Leo","Mia","Jade","Finn","Nia","Ace"]
  },
  {
    "name": "Noah Davis",
    "value": [0],
    // "value": [0, 15, 32, 48, 76, 29, 63, 58, 91, 37],
    "valueName": ["Zara"]
    // "valueName": ["Zara","Liam","Eva","Max","Leo","Mia","Jade","Finn","Nia","Ace"]
  },
  {
    "name": "Emma Wilson long",
    "value": [-15],
    // "value": [-15, -32, -5, -28, -77, -48, -16, -50, -21, -99],
    "valueName": ["Zara"]
    // "valueName": ["Zara","Liam","Eva","Max","Leo","Mia","Jade","Finn","Nia","Ace"]
  },
  {
    "name": "Ava Garcia",
    "value": [17],
    // "value": [17, 22, 90, 58, 61, 79, 25, 34, 72, 67],
    "valueName": ["Zara"]
    // "valueName": ["Zara","Liam","Eva","Max","Leo","Mia","Jade","Finn","Nia","Ace"]
  },
  {
    "name": "Isabella Martinez",
    "value": [12],
    // "value": [12, 53, 61, 34, 98, 70, 48, 77, 56, 91],
    "valueName": ["Zara"]
    // "valueName": ["Zara","Liam","Eva","Max","Leo","Mia","Jade","Finn","Nia","Ace"]
  },
  {
    "name": "Lucas Rodriguez",
    "value": [-21],
    // "value": [-21, -9, -33, -11, -55, -37, -48, -72, -18, -49],
    "valueName": ["Zara"]
    // "valueName": ["Zara","Liam","Eva","Max","Leo","Mia","Jade","Finn","Nia","Ace"]
  },
  {
    "name": "Mia Lee",
    "value": [61],
    // "value": [61, 94, 29, 80, 14, 53, 88, 17, 65, 45],
    "valueName": ["Zara"]
    // "valueName": ["Zara","Liam","Eva","Max","Leo","Mia","Jade","Finn","Nia","Ace"]
  },
  {
    "name": "Elijah Perez",
    "value": [32],
    // "value": [32, 57, 86, 21, 63, 77, 42, 50, 67, 82],
    "valueName": ["Zara"]
    // "valueName": ["Zara","Liam","Eva","Max","Leo","Mia","Jade","Finn","Nia","Ace"]
  },
  {
    "name": "Sophia Taylor",
    "value": [22],
    // "value": [22, 46, 78, 58, 93, 61, 37, 81, 15, 69],
    "valueName": ["Zara"]
    // "valueName": ["Zara","Liam","Eva","Max","Leo","Mia","Jade","Finn","Nia","Ace"]
  },
  {
    "name": "Bob name is a little bit longer than anyone else ab",
    "value": [11],
    // "value": [11, 72, 28, 55, 94, 43, 34, 60, 12, 40],
    "valueName": ["Zara"]
    // "valueName": ["Zara","Liam","Eva","Max","Leo","Mia","Jade","Finn","Nia","Ace"]
  }
]);
const barChartEl = ref(null); // The element of the bar chart container
const barHoverIndex = ref(-1); // The hover index to manipulate the opacity of each bar, -1 is not hovering to any bar
const legendHoverIndex = ref(-1); // The hover index of the legend, -1 is not hovering on any legend

const yAxisTop = ref(10); // The y axis top margin
const yAxisBottom = ref(40); // The y axis bottom margin
const yAxisLeft = ref(50); // The y axis left margin
const yAxisValues = ref([]); // The values and position of the y axis values

const xAxisLinePosition = ref(0); // The position of x axis
const bottomLinePosition = ref(0); // The last position of the value in the bar chart

const legendToShow = ref([]); // The list of legends to show
const emptyLegends = ref([]); // The list of legends that has no amount and will be hidden
const showEmptyLegends = ref(false); // This is to show or hide the empty legends
//#endregion Data

//#region Methods
const calculateBarInterval = () => {
  // Getting the size of the container element
  let containerHeight = barChartEl.value.getBoundingClientRect().height;

  // Getting the max and min value of the total value
  let maxValue = Math.max(...barData.value.map(b => b.totalValue));
  let minValue = Math.min(...barData.value.map(b => b.totalValue));

  // Set the intervals values
  yAxisValues.value = getYAxisValues(minValue, maxValue, containerHeight);

  // Calculate each of the bar height
  calculateBarHeight();
}
const getYAxisValues = (minValue, maxValue, containerHeight) => {
  // Closest minimum value to multiple of 5
  let min = Math.floor(minValue / 5) * 5;
  // Closest maximum value to multiple of 5
  let max = Math.ceil(maxValue / 5) * 5;

  // Set the min and max to -1 and 1 if the min and max is 0
  if (min == 0 && max == 0) {
    min = -1;
    max = 1;
  }

  // Set the min to 0 if it is more than 0
  if (min > 0) {
    min = 0;
  }

  // Get the range from the max to min
  let range = max - min;

  // Get the interval closest to higher multiple of 5
  let interval = range / 8;
  interval = Math.ceil(interval / 5) * 5;

  // Init the interval arrays
  let intervalArray = [ 0 ];
  
  // Create the negative intervals
  let start = 0;
  while (start > min) {
    start -= interval
    intervalArray.push(start);
  }

  // Create the positive intervals
  start = 0;
  while (start < max) {
    start += interval;
    intervalArray.push(start);
  }

  // Sort the intervals in descending order
  intervalArray.sort((a,b) => b - a);

  // Calculating the y axis height
  let topPosition = yAxisTop.value;
  let bottomPosition = containerHeight - yAxisTop.value;
  let yAxisHeight = bottomPosition - topPosition;

  // Calculate each interval position
  let intervalGap = yAxisHeight / intervalArray.length;
  let top = topPosition;
  intervalArray = intervalArray.map(i => {
    // Creating the interval object
    let obj = {
      value: i,
      top: top
    };
    
    // Add the top with the interval gap
    top += intervalGap;

    return obj;
  });

  // Getting the x axis position
  let xAxisPosition = intervalArray.find(i => i.value == 0);
  xAxisLinePosition.value = xAxisPosition.top + yAxisTop.value;

  // Setting the most bottom line position
  let lastPosition = intervalArray.at(-1);
  bottomLinePosition.value = lastPosition.top;

  return intervalArray;
}
const calculateBarHeight = () => {
  // Get the most top and bottom value
  let topValue = yAxisValues.value[0].value;
  let bottomValue = yAxisValues.value.at(-1).value;

  // Getting the top and bottom section height
  let topHeight = xAxisLinePosition.value - yAxisTop.value - 10;
  let bottomHeight = bottomLinePosition.value - xAxisLinePosition.value + 10;

  // Calculate the height in percentage
  barData.value.forEach(b => {
    if (b.totalValue < 0) {
      b.height = b.totalValue / bottomValue * bottomHeight;
    } else {
      b.height = b.totalValue / topValue * topHeight;
    }
  });
}
const getBarContainerStyle = () => {
  // Use the left and top based on the y axis line
  let top = yAxisTop.value * 2;
  let left = yAxisLeft.value + 20;

  // Calculate the width based on the left value
  let width = `calc(100% - ${left + 30}px)`;
  // Calculate the height based on the x axis position
  let height = `${xAxisLinePosition.value - top}px`;

  return {
    top: `${top}px`,
    left: `${left}px`,
    width: width,
    height: height
  };
}
const isBarHover = (ind) => {
  // If -1 or index same as the hover index, then true to show full opacity
  if (barHoverIndex.value == -1 || ind == barHoverIndex.value) {
    return true;
  } else {
    return false;
  }
}
const barChartContainerSizeChanged = (e) => {
  // Getting the element of the size change
  let targetClass = e[0].target.classList;

  // Re-initialize the bar chart only if the target class is correct
  if (targetClass == 'bar-chart-container') {
    calculateBarInterval();
  }
}
const getLegendTotalCount = (legendIndex) => {
  const allCount = barData.value.map(b => b.value[legendIndex]);
  return allCount.reduce((a, b) => a + b, 0);
}
//#endregion Methods

//#region Watchers
watch(() => props.item, (val) => {
  // Set the bar data
  barData.value = val;
  // Re-initialize the chart
  calculateBarInterval();
});
//#endregion Watchers

//#region Lifecycle
onMounted(() => {
  // Checking if the item from props is not empty
  if (props.item.length > 0) {
    barData.value = props.item;
  }

  if (barData.value.length > 0) {
    // Calculate the total value for each of the bar item
    barData.value.forEach(b => b.totalValue = b.value.reduce((a,b) => a + b, 0));

    // This is to initialize the barchart
    calculateBarInterval();

    // Get the legends to show or hide
    legendToShow.value = barData.value[0].valueName.filter((l, lInd) => getLegendTotalCount(lInd) > 0);
    emptyLegends.value = barData.value[0].valueName.filter((l, lInd) => getLegendTotalCount(lInd) == 0);

    // Check if the barchart container size changed to re-initialized the chart
    new ResizeObserver(barChartContainerSizeChanged).observe(barChartEl.value);
  }
});
//#endregion Lifecycle
</script>

<style scoped>
.bar-chart-main {
  display: flex;
  flex-direction: column;
  row-gap: 5px;
  height: 100%;
  width: 100%;
}
.chart-title {
  font-weight: bold;
  text-align: center;
  width: 100%;
}
.bar-chart-container {
  height: 100%;
  width: 100%;
  position: relative;
  /* background-color: red; */
}
.y-axis-line {
  top: 10px;
  left: 40px;
  width: var(--bar-line-thick);
  background-color: black;
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.x-axis-line {
  width: calc(100% - 20px);
  height: var(--bar-line-thick);
  background-color: black;
  position: absolute;
  left: 10px;
}
.y-axis-value {
  position: absolute;
  background-color: black;
  height: var(--bar-line-thick);
  width: 20px;
  display: flex;
  align-items: center;
}
.y-axis-value > div {
  transform: translateX(calc(-100% - 3px));
  font-size: 0.8em;
}
.bar-container {
  /* background-color: green; */
  position: relative;
  height: 100px;
  width: 100%;
  display: flex;
  align-items: flex-end;
  column-gap: 10px;
}
.bar-item {
  height: 200px;
  width: 100%;
  max-width: 150px;
  position: relative;
  bottom: 0;
  transition: 0.3s;
}
.bar-value-text {
  width: 100%;
  text-align: center;
  position: absolute;
  font-size: 0.8em;
  transition: 0.3s;
}
.bar-name {
  overflow: hidden;
  text-overflow: ellipsis;
  position: absolute;
  padding: 0 5px;
  transition: 0.3s;
  word-break: break-all;
}
.chart-container {
  display: flex;
  width: 100%;
  height: 100%;

  > .legend-container {
    min-width: 250px;
    max-width: 250px;
    width: 250px;
    display: flex;
    flex-direction: column;
    overflow: auto;
    height: calc(100% - 50px);

    > .legend-title {
      font-weight: bold;
    }

    > .legend-item {
      display: flex;
      align-items: center;
      gap: 5px;
      cursor: default;
      user-select: none;
      padding-top: 5px;

      > .legend-color {
        width: 20px;
        min-width: 20px;
        max-width: 20px;
        height: 20px;
        min-height: 20px;
        max-height: 20px;
      }

      > .legend-text {
        width: 100%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        text-align: left;
      }
    }
  }
}
</style>